<template>
  <div class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Grupos</a>
        </li>
      </ul>
    </nav>

    <button class="button m-b-md" @click="showModal = true">Adicionar</button>

    <b-table
      :data="data"
      :columns="columns"
      :paginated="true"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      default-sort-direction="asc"
      default-sort="user.first_name"
      aria-next-label="Próxima pagina"
      aria-previous-label="Pagina anterior"
      aria-page-label="Page"
      aria-current-label="Pagina atual"
      detailed
      :opened-detailed="[1]"
      detail-key="_id"
      :show-detail-icon="true"
    >
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <b-button class="m-r-sm" type="is-primary" @click="edit(props)">Editar</b-button>

              <b-button type="is-danger" @click="confirm(props)">Remover</b-button>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <Modal
      :inputs="inputs"
      :active="showModal"
      :clear="clear"
      @functionByModal="addGroup"
      @close="showModal = false"
    ></Modal>
    <Modal
      :inputs="editModule"
      :active="showModal2"
      :clear="clear"
      @functionByModal="update"
      @close="showModal2 = false"
    ></Modal>
  </div>
</template>

<script>
import Axios from "axios";

import Modal from "@/components/widgets/Modal";
export default {
  components: { Modal },
  data() {
    return {
      breadcrumb: {},
      showModal: false,
      showModal2: false,
      clear: false,
      columns: [
        {
          field: "name",
          label: "Nome",
          sortable: true
        },
        {
          field: "updatedAt",
          label: "Última alteração",
          sortable: true
        }
      ],
      inputs: [
        { label: "Nome", type: "text", model: "name", required: true },
        {
          label: "Descrição",
          type: "textarea",
          model: "description"
        }
      ],
      
      data: [],
      editModule: []
    };
  },
  methods: {
    edit(data) {
      this.editModule = [
        {
          label: "Título",
          type: "text",
          model: "name",
          name: data.row.name,
          _id: data.row._id,
          required: true
        },
        {
          label: "Descrição",
          type: "textarea",
          model: "description",
          description: data.row.description
        },
        {
          label: "Funifier APIKEY",
          type: "text",
          model: "extra.funifierApiKey",
          "extra.funifierApiKey": data.row.extra && data.row.extra.funifierApiKey
        }
      ];
      this.showModal2 = true;
    },
    update(data) {
      let dados = Object.assign({}, data);
      Axios.put(`/v1/teams/${this.editModule[0]._id}`, dados)
        .then(data => {
          if (data.data) {
            this.getGroup();
            this.showModal2 = false;
            this.$buefy.notification.open({
              message: "Dados atualizados com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    remove(data) {
      Axios.delete(`/v1/teams/${data.row._id}`)
        .then(data => {
          if (data.data) {
            this.getGroup();
            this.$buefy.notification.open({
              message: "Grupo excluido com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    addGroup(data) {
      let dados = Object.assign({}, data);
      Axios.post(`/v1/teams/`, dados)
        .then(data => {
          if (data.data) {
            this.getGroup();
            this.showModal = false;
            this.$buefy.notification.open({
              message: "Grupo criado com sucesso!",
              type: "is-success"
            });
            this.clear = !this.clear;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getGroup() {
      Axios.get(`/v1/teams`)
        .then(data => {
          if (data.data) {
            this.data = data.data.data;
            data.data.data.map((item, index) => {
              this.data[index].updatedAt = new Date(
                item.updatedAt
              ).toLocaleDateString("pt-BR");
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> este grupo?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    }
  },
  mounted() {
    this.getGroup();
  }
};
</script>

<style>
</style>
